.lift,
.track,
.grow-text {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding-left: 0.05em;
  padding-right: 0.05em;
}

.animated-lift,
.lift:before,
.animated-track,
.track:before,
.animated-grow,
.grow-text:before {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  padding-left: 0.05em;
  padding-right: 0.05em;
}

.lift:before {
  content: "Lift.";
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  /* margin-bottom: -10px; */
  background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.75));
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.track:before {
  content: "Track.";
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.75));
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.grow-text:before {
  content: "Grow.";
  position: absolute;
  display: block;
  width: 100%;
  text-align: center;
  background: linear-gradient(180deg, #fff, hsla(0, 0%, 100%, 0.75));
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.lift-shadow,
.track-shadow,
.grow-shadow {
  position: absolute;
  bottom: 50px;
  filter: blur(50px);
  width: 100%;
  z-index: 0;
}

.animated-lift {
  filter: contrast(1.2);
  background-image: linear-gradient(90deg, #5923f9, #7351ff);
  position: relative;
  animation: animationFade-lift 8s ease infinite normal none running;
  animation-delay: 0s;
  z-index: 1;
}

.animated-lift:after,
.lift-shadow {
  padding-left: 0.05em;
  padding-right: 0.05em;
  background-image: linear-gradient(90deg, #5923f9, #7351ff);
  position: relative;
}

.animated-track,
.track-shadow {
  background-image: linear-gradient(90deg, #7351ff, #db2777);
  animation: animationFade-opposing 8s ease infinite normal none running;
  animation-delay: 0s;
}

.animated-grow,
.grow-shadow {
  background-image: linear-gradient(90deg, #ea580c, #eab308);
  animation: animationFade-grow 8s ease infinite normal none running;
  animation-delay: 0s;
}

.animated-track,
.animated-grow {
  filter: contrast(1.2);
  position: relative;
  z-index: 1;
}

.track-shadow,
.grow-text {
  padding-left: 0.05em;
  padding-right: 0.05em;
  position: relative;
}

@keyframes animationFade-lift {
  0%,
  16.667%,
  100% {
    opacity: 1;
  }

  33.333%,
  83.333% {
    opacity: 0;
  }
}

@keyframes animationFade-opposing {
  0%,
  100% {
    opacity: 0;
  }

  33.333%,
  50% {
    opacity: 1;
  }
  16.667%,
  66.667% {
    opacity: 0;
  }
}

@keyframes animationFade-grow {
  0%,
  50%,
  100% {
    opacity: 0;
  }

  66.667%,
  83.333% {
    opacity: 1;
  }
}

.lift-animated-border-btn {
  position: absolute;
  background-image: linear-gradient(90deg, #5923f9, #7351ff);
  filter: contrast(1.5);
  z-index: 10;
}

.lift-animated-border-btn {
  animation: animationFade-lift 8s ease infinite normal none running;
  animation-delay: 0s;
}

.track-animated-border-btn {
  position: absolute;
  background-image: linear-gradient(90deg, #7351ff, #db2777);
  animation: animationFade-track 8s ease infinite normal none running;
  filter: contrast(1.5);
  animation-delay: 0s;
  z-index: 1;
}

.grow-animated-border-btn {
  position: absolute;
  background-image: linear-gradient(90deg, #ff4d4d, #f9cb28);
  animation: animationFade-grow 8s ease infinite normal none running;
  filter: contrast(1.5);
  animation-delay: 0s;
  z-index: 1;
}

.animated-shadow-border-lift {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(90deg, #007cf0, #00dfd8);
  filter: blur(50px);
  animation: animationFade-lift 8s ease infinite normal none running;
  z-index: 1;
}

.animated-shadow-border-track {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(90deg, #7928ca, #ff0080);
  filter: blur(50px);
  animation: animationFade-track 8s ease infinite normal none running;
  z-index: 1;
}

.animated-shadow-border-orange,
.animated-shadow-border-grow {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(90deg, #ea580c, #eab308);
  filter: blur(50px);
  z-index: 1;
}

.animated-shadow-border-grow {
  animation: animationFade-grow 8s ease infinite normal none running;
}
